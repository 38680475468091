// 
// columnResizer.scss
// 

.resizer {
    position: absolute;
    top: 0;
    opacity: 0;
    right: 0%;
    height: 100%;
    width: 2px;
    background: rgba(0, 0, 0, 0.5);
    cursor: col-resize;
    user-select: none;
    touch-action: none;
}

.resizer.ltr {
    right: 1;
}

.resizer.rtl {
    left: 0;
}

.resizer.isResizing {
    background: rgb(189, 117, 22);
    opacity: 1;
}

@media (hover: hover) {
    .resizer {
        opacity: 0;
    }

    *:hover>.resizer {
        opacity: 1;
    }
}