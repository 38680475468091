
//
// Form-Upload
//

/* Dropzone */
.dropzone {
  min-height: 10px;
  border: 2px dashed var(--#{$prefix}border-color);
  background: var(--#{$prefix}white);
  border-radius: 6px;

  .dz-message {
    font-size: 16px;
    width: 100%;
    padding: 0px;
  }
}
