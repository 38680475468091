// 
// _preloader.scss
//

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $card-bg;
    z-index: 9999;
}

#status {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%);
    margin: -20px 0 0 -20px;
}

.spinner{
    .spin-icon {
        font-size: 48px;
        color: $primary;
        position: relative;
        display: inline-block;
        animation: spin 1.6s infinite linear;
    }
}

// .placeholder {
//     display: inline-block;
//     min-height: 1em;
//     vertical-align: middle;
//     cursor: wait;
//     background-color: currentcolor;
//     opacity: 0.5;
// }

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
}